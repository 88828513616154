@use 'src/assets/scss/web-ui';

.cardGroup > div:not(:last-child) {
  margin-bottom: 24px;
}

.headerWrapper {
  display: flex;
  align-items: center;
}

.header {
  font-size: 20px;
  line-height: 32px;
  border-bottom: 1px solid web-ui.$color-border-divider-ink-black;
  padding-bottom: 8px;
  margin-bottom: 14px;
  margin-left: 8px;
  margin-right: 8px;
  @include web-ui.breakpoint(m) {
    margin-left: 0px;
    margin-right: 0px;
  }
}

.headerIcon {
  width: 72px;
  height: auto;
}
