$ValidationMessage-icon-width: 1rem;
$ValidationMessage-icon-margin: 0.5rem;

.ValidationMessage {
  border-radius: 0.25rem; //$cards-borderRadius;
  background-color: rgba(241, 204, 90, 0.3); //$colors-legacyYellowO30;
  color: rgba(25, 25, 25, 1); //$colors-brand-inkBlack;
  display: flex;
  //font-family: $typography-main;
  font-size: 0.75rem;
  font-weight: bold;
  line-height: 1.5;
  padding: 1rem;
  position: relative;
  margin-bottom: 1rem; //$componentSpacing-s;

  &--with-arrow {
    margin: -0.75rem 0 1rem; //$componentSpacing-s;

    &:before {
      content: '';
      bottom: 100%;
      border: solid transparent;
      border-bottom-color: rgba(241, 204, 90, 0.3); //$colors-legacyYellowO30;
      border-width: 0.5rem;
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      left: 1.25rem;
    }
  }

  &__icon {
    margin-right: 0.5rem;
    height: $ValidationMessage-icon-width;
    width: $ValidationMessage-icon-width;
  }

  &__message {
    width: calc(100% - (#{$ValidationMessage-icon-width} + #{$ValidationMessage-icon-margin}));
  }

  &--error {
    background: rgba(209, 51, 74, 0.1); //$colors-errorO10;
    color: rgba(209, 51, 74, 1); //$colors-error;

    .ValidationMessage__icon {
      fill: rgba(209, 51, 74, 1); //$colors-error;
    }

    &:before {
      border-bottom-color: rgba(209, 51, 74, 0.1); //$colors-errorO10
    }
  }

  &--success {
    background: rgba(85, 164, 105, 0.1); //$colors-successO10;
    color: rgba(85, 164, 105, 1); //$colors-success;

    .ValidationMessage__icon {
      fill: rgba(85, 164, 105, 1); //$colors-success;
    }

    &:before {
      border-bottom-color: rgba(85, 164, 105, 0.1); //$colors-successO10;
    }
  }

  &--warning {
    background: rgba(255, 246, 237, 1); //$colors-warningLight;
    color: rgba(225, 113, 0, 1); //$colors-warning;

    .ValidationMessage__icon {
      fill: rgba(225, 113, 0, 1); //$colors-warning;
    }

    &:before {
      border-bottom-color: rgba(255, 246, 237, 1); //$colors-warningLight;
    }
  }

  a {
    color: currentColor;

    &:hover {
      opacity: 0.8;
    }
  }

  p {
    margin: 0;
  }

  &--inline {
    margin-top: 0;
  }
}
