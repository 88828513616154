.status {
  padding: 16px 16px;
  border-radius: 4px;
  margin-bottom: 24px;

  :last-child {
    margin-bottom: 0;
  }
}

.centerText {
  text-align: center;
}
