.TextInput {
  //font-family: $typography-main;

  max-width: 29rem; //$inputs-maxWidth;

  &__error {
    display: block;
    font-size: 0.875rem;
    margin-bottom: 1rem; //$componentSpacing-s;
  }

  &__wrapper {
    &--withBtn {
      display: flex;

      .TextInput__status {
        width: 100%;
      }

      button {
        min-width: max-content;
        margin-left: 1rem;
      }
    }
  }

  &__status {
    position: relative;
  }

  &__status-icon {
    position: absolute;
    top: calc(2.625rem / 2); //$inputs-height
    right: 1rem;
    transform: translateY(-50%);
    height: 1rem;
    width: 1rem;

    &--error {
      fill: rgba(209, 51, 74, 1); //$colors-error;
    }
  }

  &__label-wrapper {
    display: flex;
  }

  &__label {
    color: rgba(25, 25, 25, 1); //$colors-brand-inkBlack;
    display: block;
    font-size: 0.875rem;
    margin-bottom: 0.5rem; //$componentSpacing-xs;
  }

  &__input {
    border-radius: 0.25rem; //$inputs-borderRadius;
    border: 1px solid rgba(25, 25, 25, 0.25); //$colors-brand-inkBlackO25;
    font-size: 1rem;
    height: 2.625rem; //$inputs-height;
    margin-bottom: 1.5rem; //$componentSpacing-m;
    padding: 0 1rem;
    width: 100%;
    transition: all 300ms ease-in-out;

    &:hover:not(:disabled):not(:focus):not(:active) {
      border-color: rgba(25, 25, 25, 0.6); //$colors-brand-inkBlackO60;
    }

    &:focus,
    &:active {
      border: 1px solid rgba(0, 158, 232, 1); //$colors-brand-firstBlue;
      outline: none;
      box-shadow: 0 4px 12px 0 rgba(0, 158, 232, 0.2); //$colors-brand-firstBlueO20;
    }
  }

  &--error {
    .TextInput__label {
      color: rgba(209, 51, 74, 1); //$colors-error;
    }

    .TextInput__input {
      border-color: rgba(209, 51, 74, 1); //$colors-error;
      color: rgba(209, 51, 74, 1); //$colors-error;

      &:focus,
      &:active {
        box-shadow: 0 4px 12px 0 rgba(209, 51, 74, 0.2); //$colors-errorO20;
      }
    }
  }

  &--disabled {
    .TextInput__label {
      opacity: 0.4;
    }

    .TextInput__input {
      background: rgba(25, 25, 25, 0.05); //$colors-brand-inkBlackO05;
      border: 1px solid rgba(25, 25, 25, 0.1); //$colors-brand-inkBlackO10;
    }
  }
}
.TextArea__input {
  border-radius: 4px;
  border: 1px solid rgba(25, 25, 25, 0.25);
  font-size: 16px;
  margin-bottom: 24px;
  width: 100%;
  transition: border 300ms ease-in-out, box-shadow 300ms ease-in-out;
  padding: 16px;

  &:hover:not(:disabled):not(:focus):not(:active) {
    border-color: rgba(25, 25, 25, 0.6); //$colors-brand-inkBlackO60;
  }

  &:focus,
  &:active {
    border: 1px solid rgba(0, 158, 232, 1); //$colors-brand-firstBlue;
    outline: none;
    box-shadow: 0 4px 12px 0 rgba(0, 158, 232, 0.2); //$colors-brand-firstBlueO20;
  }
}
