.Select {
  position: relative;
  margin-bottom: 1.5rem; //$componentSpacing-m
  max-width: 29rem; //$inputs-maxWidth

  &__input {
    -moz-appearance: window;
    -webkit-appearance: none;
    background: white;
    border-radius: 0.25rem; //$inputs-borderRadius;
    border: 1px solid rgba(25, 25, 25, 0.25); //$colors-brand-inkBlackO25;
    height: 2.625rem; //$inputs-height
    padding: 0 1rem;
    width: 100%;
    transition: all 300ms ease-in-out; //$animation-defaultTransition;

    &:hover,
    &:focus,
    &:active {
      border-color: rgba(25, 25, 25, 0.6); //$colors-brand-inkBlackO60;
      outline: none;
    }

    option {
      color: rgba(25, 25, 25, 1); //$colors-brand-inkBlack;
    }
  }

  &__arrow {
    height: 16px;
    width: 16px;
    fill: rgba(25, 25, 25, 0.25); //$colors-brand-inkBlackO25;
    position: absolute;
    right: 1px;
    top: 50%;
    margin-top: -5px;
    padding-right: calc(1rem - 1px);
    background: transparent;
    box-sizing: content-box;
    pointer-events: none;
  }

  &--error {
    .Select__input {
      border-color: rgba(209, 51, 74, 1); //$colors-error;
    }
  }

  &__icon_error {
    position: absolute;
    top: calc(2.625rem / 2); //$inputs-height
    right: 1rem;
    transform: translateY(-50%) translateX(-120%);
    height: 1rem;
    width: 1rem;
  }
}
