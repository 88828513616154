@use 'src/assets/scss/web-ui';

// u-{breakpoint}-[down]-wide will force an element to be width 100% for a
// given breakpoint and up/down.
@each $breakpoint, $bpSize in web-ui.$breakpoints {
  @include web-ui.breakpoint($breakpoint 'down') {
    .u-#{$breakpoint}-down-wide {
      width: 100%;
    }
  }

  @include web-ui.breakpoint($breakpoint) {
    .u-#{$breakpoint}-wide {
      width: 100%;
    }
  }
}
