@use 'src/assets/scss/web-ui';

// Hides block child element corners that overflow the card/surface's rounded corners
.card {
  overflow: hidden;

  @include web-ui.breakpoint(s down) {
    border-radius: 20px !important;
  }
}

.transparent {
  background-color: transparent;
}

.fullBleed {
  padding: 0;
}
