@import './Checkbox.scss';
@import './Error.scss';
@import './Input.scss';
@import './ValidationMessage.scss';
@import './SelectInput.scss';
@import './Label.scss';

// Below labs.ui-components overrides were applied within the payments-tokenization repo,
// which supplies the tokenizer form.
// So we have to replicate these overrides for the surrounding fields on the page.
.TextInput__input {
  @extend .TextInput__input;
  @media (max-width: 24rem) {
    margin-bottom: 1rem;
  }
}

.Error {
  @extend .Error;
  line-height: unset;
}

#contribution-form-container {
  // match everything on the page to the tokenizer font
  font-family: 'EFCircularWeb Book Web', Arial, sans-serif;
}
