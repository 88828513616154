// overrides.scss is full component-level style overrides. Do not re-assign variables.
// Use _web-ui.scss for variable/setting overrides.

@use 'src/assets/scss/web-ui';

.ef-link,
.ef-button.-link {
  color: web-ui.$color-ui-digital-first-blue;
  text-decoration: none;

  &:hover {
    color: web-ui.$color-ui-digital-first-blue;
    text-decoration: underline;
  }

  &:focus {
    color: web-ui.$color-ui-digital-first-blue;
  }

  &:active {
    color: web-ui.$color-ui-digital-first-blue-active;
  }
}

@mixin efButtonStates {
  &:hover {
    background: web-ui.$color-ui-digital-first-blue-hover;
    border-color: web-ui.$color-ui-digital-first-blue-hover;
    box-shadow: 0 4px 12px web-ui.$color-digital-first-blue-40;
    color: web-ui.$color-white;
  }

  &:focus {
    background: web-ui.$color-ui-digital-first-blue-hover;
    background-color: none;
    border-color: web-ui.$color-ui-digital-first-blue-hover;
    box-shadow: 0 0 0 2px web-ui.$color-white, 0 0 0 4px web-ui.$color-ui-digital-first-blue-hover,
      0 4px 12px web-ui.$color-digital-first-blue-30;
    color: web-ui.$color-white;
  }

  &:active {
    background: web-ui.$color-ui-digital-first-blue-active;
    border-color: web-ui.$color-ui-digital-first-blue-active;
    box-shadow: 0 0 0 2px web-ui.$color-white, 0 0 0 4px web-ui.$color-ui-digital-first-blue-hover,
      0 4px 12px web-ui.$color-digital-first-blue-40;
    color: web-ui.$color-white;
  }
}

.ef-button {
  background: none;
  background-color: none;
  border-radius: 100px;
  box-shadow: none;
  font-weight: 600;
  padding: 12px 24px;

  &.-outlined,
  &.-filled,
  &.-primary {
    &.-small {
      padding: 8px 16px;
    }
  }

  &.-primary {
    background: web-ui.$color-ui-digital-first-blue;
    box-shadow: none;

    &:disabled,
    &[disabled] {
      // apply to disabled RouterLinkButton
      background: web-ui.$color-ui-digital-first-blue-60;
      opacity: 1;
    }

    @include efButtonStates;
  }

  &.-outlined {
    background: transparent;
    border-color: web-ui.$color-ui-digital-first-blue;
    color: web-ui.$color-ui-digital-first-blue;

    &:disabled,
    &[disabled] {
      // apply to disabled RouterLinkButton
      background-color: web-ui.$color-ink-black-08;
      border-color: rgba(0, 104, 224, 0.6);
      color: rgba(0, 104, 224, 0.6);
      opacity: 1;
    }

    @include efButtonStates;
  }

  &.-filled {
    border-color: web-ui.$color-ink-black;
    box-shadow: none;
    color: web-ui.$color-ink-black;

    &:hover {
      color: web-ui.$color-white;
      background: web-ui.$color-ink-black;
      border-color: web-ui.$color-ink-black;
    }

    &:focus {
      background: web-ui.$color-ink-black;
      border-color: web-ui.$color-ink-black;
      box-shadow: 0 0 0 2px web-ui.$color-white, 0 0 0 4px web-ui.$color-ink-black, 0 4px 12px web-ui.$color-ink-black;
      color: web-ui.$color-white;
    }

    &:active {
      background: web-ui.$color-ink-black-72;
      border-color: web-ui.$color-ink-black-72;
      box-shadow: 0 0 0 2px web-ui.$color-white, 0 0 0 4px web-ui.$color-ink-black, 0 4px 12px web-ui.$color-ink-black;
      color: web-ui.$color-white;
    }

    &:disabled,
    &[disabled] {
      // apply to disabled RouterLinkButton
      color: web-ui.$color-ink-black-60;
      background-color: web-ui.$color-ink-black-08;
      border-color: web-ui.$color-ink-black-30;
      opacity: 1;
    }
  }
}

.ef-input:disabled,
.ef-input[disabled],
.ef-input[readonly] {
  -webkit-text-fill-color: #999;
}

@include web-ui.breakpoint(s down) {
  .ef-table-w {
    width: 100vw;
    position: relative;
    left: calc(-50vw + 50%);
  }
}
