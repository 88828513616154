// Re-export Web-UI Library's global SASS helpers as well as variable overrides. If you need
// to reference a variable, mixin, or anything else in web-ui, do it through this file and
// @use in downstream scss modules.
//
// WARNING: DO NOT IMPORT SCSS THAT GENERATES CSS FROM WEB-UI IF YOU VALUE BUILD TIMES,
// BROWSER PERFORMANCE, AND BUNDLE SIZES. If you find you need access to SCSS, add the
// specific file you need.

@import '~@ef-global/web-ui/scss/settings/_all';

// Colors
$color-digital-first-blue: rgba(0, 157, 230, 1); // #009DE6 Override of GUD Digital First Blue
$color-digital-first-blue-80: rgba($color-digital-first-blue, 0.8);
$color-digital-first-blue-40: rgba($color-digital-first-blue, 0.4);
$color-digital-first-blue-30: rgba($color-digital-first-blue, 0.3);

$color-ui-digital-first-blue: rgba(0, 104, 224, 1); // #0068E0
$color-ui-digital-first-blue-60: rgba($color-ui-digital-first-blue, 0.6);

$color-ui-digital-first-blue-hover: rgba(0, 67, 221, 1); // #0043dd;
$color-ui-digital-first-blue-focus: $color-ui-digital-first-blue-hover;
$color-ui-digital-first-blue-active: rgba(0, 33, 197, 1); // #0021C5;

$color-information-blue: rgba(0, 60, 205, 1);

$color-bg-success: $color-outdoor-paper;
$color-bg-primary: $color-ui-digital-first-blue;
$color-bg-warning: $color-legal-paper;
$color-bg-info: $color-first-paper;
$color-bg-error: $color-hello-paper;
$color-bg-muted: $color-ef-paper;

$color-text-muted: rgba(25, 25, 25, 0.72);
$color-text-success: $color-notification-success;
$color-text-warning: #c13e00;
$color-text-error: #d00025;

// AAA compliant. Use this shade for copy on gray/color backgrounds
$color-ink-black-72: rgba($color-ink-black, 0.72);
// AA compliant. Use this shade for copy on white backgrounds
$color-ink-black-60: rgba($color-ink-black, 0.6);
$color-ink-black-30: rgba($color-ink-black, 0.3);
$color-ink-black-08: rgba($color-ink-black, 0.08);

$color-education-paper-50: rgba($color-education-paper, 0.5);
$color-education-paper-40: rgba($color-education-paper, 0.4);

$color-first-paper-50: rgba($color-first-paper, 0.5);

// Web-UI Library only provides generic vars for certain things (like colors).
// For instance, surfaces use $surface--shadow--box-shadow and there is no generic box shadow.
$shadow--box-shadow: 0 2px 8px 0 rgba(25, 25, 25, 0.16);

// Borders
$color-border-divider-ink-black: rgba($color-ink-black, 0.15);
$color-border-divider-white: rgba($color-white, 0.3);
