@use 'src/assets/scss/web-ui';

.cardHeader {
  border-bottom: 2px solid web-ui.$color-ef-grey;
  padding-right: 24px;
  padding-bottom: 16px;
  padding-left: 24px;
  margin-left: -24px;
  margin-bottom: 24px;
  margin-right: -24px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0;
  }

  h2 {
    line-height: 28px;
  }
}

.header {
  font-size: 20px;
  padding-top: 4px;
}
