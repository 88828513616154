@use 'src/assets/scss/web-ui';

.mainFooter {
  color: web-ui.$color-ink-black-72;
  border-top: 1px solid web-ui.$color-border-divider-ink-black;
  font-size: 12px;
  padding: 15px;
  align-self: flex-end;
  width: 100%;
  overflow-y: hidden;
  background-color: web-ui.$color-ef-paper;
}

.mainFooterContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  // break out of ef-container margin flow for mobile to prevent text stacking
  @include web-ui.breakpoint(m down) {
    margin: 0 12px;
  }
}

.mainFooterFooterLeft {
  @include web-ui.breakpoint(m down) {
    max-width: 240px;
  }
}

.mainFooterLeftWrapper {
  display: flex;
  flex-direction: column;
}

.mainFooterFooterLeft,
.mainFooterFooterRight {
  a {
    color: web-ui.$color-text-muted;
    text-decoration: underline;
    cursor: pointer;

    &:visited,
    &:hover,
    &:active {
      color: web-ui.$color-text-muted;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}

.fullPage {
  // caution: see .fullPage in Main.module.scss to ensure compatibility of any changes
  position: absolute;
  bottom: 0;
}
