@use 'src/assets/scss/web-ui';

@mixin status-border-color {
  // we have different inner borders for status colors
  & > * {
    border-color: web-ui.$color-border-divider-ink-black !important; // FIXME, don't love that I have to have Important here
  }
}

// We have to have a base wrapper otherwise css specificity doesn't guarantee color overrides
.statusColor {
  &.white {
    background-color: web-ui.$color-white;
  }

  &.primary {
    background-color: web-ui.$color-bg-primary;

    & > * {
      border-color: #ffffff30 !important;
    }

    &.text {
      color: web-ui.$color-white;
    }
  }

  &.success {
    background-color: web-ui.$color-bg-success;
    @include status-border-color;

    &.text {
      color: web-ui.$color-notification-success;
    }
  }

  &.warning {
    background-color: web-ui.$color-bg-warning;
    @include status-border-color;

    &.text {
      color: web-ui.$color-text-warning;
    }
  }

  &.info {
    background-color: web-ui.$color-bg-info;
    @include status-border-color;
  }

  &.error {
    background-color: web-ui.$color-bg-error;
    @include status-border-color;

    &.text {
      color: web-ui.$color-text-error;
    }
  }

  &.muted {
    background-color: web-ui.$color-bg-muted;

    &.text {
      fill: web-ui.$color-ink-black-72;
      color: web-ui.$color-ink-black-72;
    }
  }
}
