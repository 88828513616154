@use 'src/assets/scss/web-ui';

.Tooltip {
  // Per the react-tooltip docs, just use !important to override the base
  // if you prefer to use your sass variables instead of html attributes
  color: web-ui.$color-ink-black !important;
  opacity: 1 !important;
  box-shadow: web-ui.$shadow--box-shadow;

  // Max-width, otherwise it becomes unreasonably wide on desktop
  // And if you use <br> for line breaks, natural line wrapping can still occur on small
  // screens and create a really weird rag on the text.
  max-width: 80vw;

  &::after {
    opacity: 1 !important;
  }

  span {
    text-align: left !important;
  }

  a {
    color: web-ui.$color-information-blue !important;
  }
}

@include web-ui.breakpoint(m) {
  .Tooltip {
    max-width: 30vw;
  }
}

.InfoIconWithTooltip {
  display: inline-block;
  width: 2em;
  height: 2em;
  margin: -0.4em -0.5em -0.6em -0.5em;
  vertical-align: baseline;
  position: relative;
}

.Icon {
  width: 1em;
  height: 1em;
  position: absolute;
  left: 0.5em;
  top: 0.5em;
}
