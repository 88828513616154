.Checkbox {
  //font-family: $typography-main;
  display: flex;
  margin-bottom: 1rem;
  max-width: 29rem; //$inputs-maxWidth;
  position: relative;

  &__error {
    display: flex;
    align-items: center;
  }

  &__error-text {
    font-size: 0.75rem;
    //font-family: $typography-bold;
    font-weight: bold;
    color: rgba(209, 51, 74, 1); //$colors-error;
  }

  &__error-icon {
    margin-right: 0.5rem;
    overflow: visible;
    fill: rgba(209, 51, 74, 1); //$colors-error;
    width: 1rem;
    height: 1rem;
    position: absolute;
    margin-left: 0.5rem;
    padding-top: 2.25rem;
  }

  &__check {
    width: 1.5rem;
    height: 1.5rem;
    fill: white;
    background: rgba(0, 158, 232, 1); //$colors-brand-firstBlue;
    padding: 2px;
  }

  &__input {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);

    &:checked ~ .Checkmark .Checkmark__check {
      fill: white;
      opacity: 1;
    }

    &:checked ~ .Checkmark .Checkmark__inner {
      fill: rgba(0, 158, 232, 1); //$colors-brand-firstBlue;
      stroke: rgba(0, 158, 232, 1); //$colors-brand-firstBlue;
    }

    &:focus ~ .Checkmark .Checkmark__outer {
      opacity: 1;
    }
  }

  &__label {
    font-size: 1rem;
    line-height: 1.5;
    min-height: 1.875rem; //$inputs-focusWidth;
    display: flex;
    padding-left: 2.875rem;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    &:hover ~ .Checkmark .Checkmark__inner {
      stroke: rgba(0, 158, 232, 1); //$colors-brand-firstBlue;
    }
  }

  &--disabled {
    .Checkbox__label:hover ~ .Checkmark .Checkmark__inner {
      stroke: rgba(25, 25, 25, 0.1); //$colors-brand-inkBlackO10;
    }

    .Checkmark__inner {
      fill: rgba(25, 25, 25, 0.05); //$colors-brand-inkBlackO05;
      stroke: rgba(25, 25, 25, 0.1); //$colors-brand-inkBlackO10;
    }

    .Checkbox__label {
      color: rgba(25, 25, 25, 0.5); //$colors-brand-inkBlackO50;
    }

    .Checkbox__input:checked ~ .Checkmark .Checkmark__inner {
      fill: rgba(25, 25, 25, 0.05); //$colors-brand-inkBlackO05;
      stroke: rgba(25, 25, 25, 0.1); //$colors-brand-inkBlackO10;
    }

    .Checkbox__input:checked ~ .Checkmark .Checkmark__check {
      fill: 1px solid rgba(25, 25, 25, 0.25); //$colors-brand-inkBlackO25;
    }
  }

  &--with-background {
    width: calc(100% + 1rem);
    margin-left: -0.5rem;
    margin-right: -0.5rem;

    .Checkbox__label {
      transition: all 300ms ease-in-out; //$animation-defaultTransition;
      background: rgba(228, 234, 238, 0.4); //$colors-brand-educationPaperO40;
      border-radius: 0.125rem;
      padding: 1.5rem 1rem 1.5rem 4rem;

      &:hover {
        background: rgba(228, 247, 255, 1); //$colors-brand-firstPaper;
      }
    }

    .Checkbox__input:checked + label {
      background: rgba(228, 247, 255, 1); //$colors-brand-firstPaper;
    }

    .Checkmark {
      top: 1.25rem;
      left: 1rem;
    }

    .Checkbox__children {
      font-size: 0.875rem;
      line-height: calc(20 / 14);
      border-top: 1px solid rgba(25, 25, 25, 0.15); //$colors-brand-inkBlackO15;
      margin: 1rem -1.5rem 0 -4rem;
      padding: 1.5rem 1rem 0;
      width: calc(100% + 5rem);

      @media screen and (min-width: 40rem) {
        //$breakpoints-sm
        margin-top: 0.5rem;
        margin-left: -4.5rem;
        padding: 1.5rem 1.5rem 0;
        width: calc(100% + 6rem);
      }
    }

    @media screen and (min-width: 40rem) {
      //$breakpoints-sm
      max-width: none;
      width: calc(100% + 3rem);
      margin-left: -1.5rem;
      margin-right: -1.5rem;

      .Checkbox__label {
        padding: 1.5rem 1.5rem 1.5rem 4.5rem;
      }

      .Checkmark {
        left: 1.5rem;
      }
    }
  }
}

.Checkmark {
  border-radius: 0.25rem; //$inputs-borderRadius;
  width: 1.875rem; //$inputs-focusWidth;
  height: 1.875rem; //$inputs-focusWidth;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 300ms ease-in-out;
  position: absolute;
  left: 0;
  pointer-events: none;

  &__check,
  &__inner,
  &__outer {
    transition: all 300ms ease-in-out; //$animation-defaultTransition;
  }

  &__check {
    fill: white;
    opacity: 0;
    pointer-events: none;
  }

  &__inner {
    stroke: rgba(25, 25, 25, 0.5); //$colors-brand-inkBlackO50;
    fill: white;
  }

  &__outer {
    opacity: 0;
    fill: rgba(25, 25, 25, 0.15); //$colors-brand-inkBlackO15;
  }

  &--error {
    stroke: rgba(209, 51, 74, 1); //$colors-error;
  }
}
