@use 'src/assets/scss/web-ui';

.mediaItem {
  margin-bottom: 24px;
}

.header {
  font-size: 16px;
  margin-bottom: 8px;
}

.media {
  vertical-align: top;
  width: 100%;
  height: auto;
  padding-top: 8px;
}

.xlargeSVG {
  width: 115px;
  height: auto;
}

.largeSVG {
  width: 80px;
  height: auto;
}

.mediumSVG {
  width: 65px;
  height: auto;
}

.smallSVG {
  width: 40px;
  height: auto;
}

.xsmallSVG {
  width: 14px;
  height: auto;
}

.primary {
  color: web-ui.$color-bg-primary;
}
