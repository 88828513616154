@use 'src/assets/scss/web-ui';

.offsetContainerForNav {
  padding-bottom: 80px;
  margin-left: 0;
  margin-right: 0;
  overflow: hidden;

  @include web-ui.breakpoint(m) {
    padding-bottom: 0;
    padding-left: 100px;
  }
}

.fullPage {
  padding-bottom: 48px;
  @include web-ui.breakpoint(m) {
    padding-left: 0;
  }
}

.mainWrapperInner {
  @media screen and (min-width: 768px) {
    max-width: 960px;
    margin: 0 32px;
  }

  @media screen and (min-width: 1124px) {
    max-width: 960px;
    margin: 0 auto;
  }

  @media screen and (min-width: 1364px) {
    max-width: 1200px;
  }
}
