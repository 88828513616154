@use 'src/assets/scss/web-ui';

.section + .section {
  border-top: 1px solid web-ui.$color-ef-grey;
  margin-top: 24px;
  padding-top: 24px;
}

.section + .sectionNoDivider {
  border-top: 0;
}

.sectionWithHeadingWrapper {
  margin-bottom: 16px;
}

.sectionWithHeadingHeader {
  font-size: 16px;
  margin-bottom: 0;
}

.sectionHero {
  background-color: web-ui.$color-ef-grey;
  margin-left: -12px;
  margin-right: -12px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 24px;
  border-radius: 4px;

  // FIXME: Border colors are currently unclear. Just using black for now.
  .sectionWithHeadingWrapper {
    border-bottom: 1px solid black;
  }
}
