@use 'src/assets/scss/web-ui';

.linkWithIcon {
  background-color: web-ui.$color-first-paper;
  color: web-ui.$color-ui-digital-first-blue !important;
  text-decoration-line: none !important;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  padding: 16px 24px;
  display: inline-flex;
  justify-content: space-between;
  align-content: center;

  svg {
    vertical-align: middle;

    path {
      fill: web-ui.$color-ui-digital-first-blue !important;
    }
  }

  &:hover,
  &:focus,
  &:hover {
    color: web-ui.$color-ui-digital-first-blue !important;
  }
}
